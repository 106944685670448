/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useLocation,
} from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify';
import './App.scss';
import 'react-toastify/dist/ReactToastify.css';
import Home from './pages/Home';
import HttpPage from './pages/Request';
import Header from './components/header';
import Competition from './pages/competition';
import MWPage from './pages/mw';
import Winners from './pages/Winners';
import Referrals from './pages/referrals';
import Notifications from './pages/notifications';
import AccountPage from './pages/account';
import { useEffect, useState } from 'react';
import RegisterComp from './pages/signup/register';
import { useAccount, useDisconnect, useSignMessage } from 'wagmi';
import Verification from './pages/signup/verification';
import { getPrice, getRegistration, getRegistrationSign, Register } from './api/services/http/api';
import useStateHook from './pages/store';
import CookiePolicy from './pages/cookiePolicy';
import PrivacyPolicy from './pages/privacyPolicy';
import TermsAndConditions from './pages/termsAndConditions';
import FAQ from './pages/FAQ';
import { LStorage, SStorage } from './api/services/cooike/storage';
import PreRegister from './pages/signup/PreRegister';
import UserInfo from './pages/useInfo';
import { add } from 'lodash';
import { RegistrationCallBack } from './types/types';


function App() {
  const [state, actions] = useStateHook();
  const { isConnected, address } = useAccount()
  const { disconnect } = useDisconnect({
    onError: () => {
    }
  })

  const [loading, setLoading] = useState(true)





  useEffect(() => {
    setTimeout(() => {
      setLoading(false)
    }, 1500)

  }, [isConnected])
  return (
    <>
      <Router>
        <Routes>
          <Route path='/' element={<PreRegister />} />
          <Route path='/signup/register' element={<RegisterComp />} />
          <Route path='/signup/verification' element={<Verification />} />
          <Route path="/userinfo" element={<UserInfo />} />
          <Route path="*" element={<Navigate to="/" replace={true} />} />
        </Routes>



        <ToastContainer theme="dark" autoClose={3000} hideProgressBar />
      </Router>
    </>
  );
}

export default App;
