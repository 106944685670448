import { useEffect, useState } from "react";
import { SStorage } from "../../api/services/cooike/storage";
import ConnectWallet from "../../components/connectWallet";
import useStateHook from "../store";

const PreRegister = (): JSX.Element => {
  const [state, actions] = useStateHook();
  const [isRegister, setIsRegister] = useState(false)
  const [useInfo, setUserInfo] = useState({
    name: '',
    email: '',
    address: ''
  })
  useEffect(() => {
    let accessToken = SStorage.get('accessToken')
    let LastAuthUser = SStorage.get('LastAuthUser')

    if (accessToken !== undefined) {
      setIsRegister(true)
      setUserInfo(LastAuthUser)
      console.log('%c🀀 LastAuthUser', 'color: #aa00ff; font-size: 20px;', LastAuthUser);
    }
    console.log('%c🀂 accessToken', 'color: #00a3cc; font-size: 20px;', accessToken);
  }, [])
  return (
    <>
      <>
        {
          state.showConnectWallet
            ? <ConnectWallet></ConnectWallet>
            : ''
        }
      </>
      <div className="min-h-screen w-full flex items-center justify-center">
        <div className="w-full">
          <div className="min-h-screen flex flex-col">

            <main className="flex items-center justify-center py-10 grow">
              <div className="px-15 py-8 pt-20 w-full max-w-lg rounded-2xl mx-3"
                style={{
                  border: '6px solid rgba(43,147,255,.43)',
                  background: 'rgba(37,62,89,.61)'
                }}
              >
                <header className="text-center mb-8"
                  style={{
                    marginTop: '-7rem',
                  }}
                >
                  <a href="/" className=""><img src={require('../../asstes/img/logo.png').default} alt="WinBig logo" width="166" height="24" className="inline-block" /></a>
                </header>
                <h1 className="text-2xl uppercase tracking-widest mb-6 text-center font-play">PRE-REGISTERTION</h1>
                {
                  isRegister
                    ? <div className="text-2xl font-heavy text-center flex items-center justify-center">
                      <div className="input-success-active"
                        style={{
                          width: '2rem',
                          height: '2rem',
                          borderRadius: '200px',
                          marginRight: '10px',
                          background: '#A2C76D'
                        }}
                      >
                        <span className={["icon2-ico-success text-2xl top-2 right-2 z-10 transition-all pointer-events-none duration-300 -translate-y-2 input-success:opacity-100 input-success:translate-y-0"].join(' ')}
                          style={{
                            color: '#253E59'
                          }}
                        ></span>
                      </div>
                      Registration Succeeded

                    </div>
                    : <p className="text-xl font-MLique text-center">
                      Welcome to Winbig- smart contract enabled PUGGY nft and Blue Chip raffles. pre-registration is now LIVE. Create your account and connect your web3.0 wallet,
                      you will get one <span className="text-gold font-BLique">FREE</span> entry into the draw.
                    </p>
                }

                {
                  isRegister
                    ? <>
                      <div className={['mt-3 relative pb-3', 'input-success-active'].join(' ')}>
                        <input
                          className="block relative w-full bg-blue-500-15 border-gary rounded-lg py-3 px-4 border-1 outline-none focus:border-slate-200 focus:ring-0 autofill:bg-blue-500-15 transition-all appearance-none hover:appearance-none disabled:text-slate-100 font-heavy"
                          name="email"
                          id="username"
                          type="text"
                          step=""
                          placeholder="Name"
                          value={useInfo.name}
                          disabled
                        />

                        <span className={["icon2-ico-success text-gold text-2xl top-2 right-2 absolute z-10 transition-all pointer-events-none duration-300 -translate-y-2 opacity-0 input-success:opacity-100 input-success:translate-y-0"].join(' ')}></span>
                      </div>
                      <div className={['mt-3 relative pb-3', 'input-success-active'].join(' ')}>
                        <input
                          className="block relative w-full bg-blue-500-15 border-gary rounded-lg py-3 px-4 border-1 outline-none focus:border-slate-200 focus:ring-0 autofill:bg-blue-500-15 transition-all appearance-none hover:appearance-none disabled:text-slate-100 font-heavy"
                          name="email"
                          id="email"
                          type="text"
                          step=""
                          placeholder="email"
                          value={useInfo.email}
                          disabled
                        />

                        <span className={["icon2-ico-success text-gold text-2xl top-2 right-2 absolute z-10 transition-all pointer-events-none duration-300 -translate-y-2 opacity-0 input-success:opacity-100 input-success:translate-y-0"].join(' ')}></span>
                      </div>

                    </>
                    : <button
                      className="text-md py-4 px-8 bg-register-btn-31 border-6 border-cyan-500 text-white rounded-full tracking-widest uppercase transition-all relative disabled:opacity-40 block w-full mt-4"

                      onClick={() => { actions.openConnect() }}

                    >
                      <span className="transition-all font-play"> Connect Wallet </span>
                      <span className="icon-ico-eth text-cyan-500 animate-glow-slow-cyan text-center absolute text-lg top-1/2 right-4 -translate-y-1/2"></span>
                    </button>
                }

                <p className="text-white/60 text-center text-base mt-5 leading-tight font-medium">By creating your account, you confirm that you are at least 18 years old</p>
              </div>


            </main>
          </div>
        </div>
      </div>
    </>
  );
}

export default PreRegister;